import React from "react"

import Layout from "../components/layout"

const NotFoundPage = ({ pageContext }) => (
  <Layout title="Not found" navItems={pageContext.categories}>
    <div className="uk-section-xsmall">
      <div className="uk-container uk-container-large uk-text-center">
        <h1>NOT FOUND</h1>
        <p>
          You just hit a route that doesn&#39;t exist... the sadness. Try
          searching for something else.
        </p>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
